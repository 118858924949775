import { useStaticQuery, graphql } from "gatsby";

const workHistoryQuery = graphql`
  query {
    workHistoryJson {
      links {
        albumId
        mastered
        mixed
        recorded
      }
    }
  }
`;

export const useWorkHistory = () => {
  const data = useStaticQuery(workHistoryQuery);
  return data.workHistoryJson || [];
};
