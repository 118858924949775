import styled from "styled-components";
import { animated } from "react-spring";

export const SidebarContainer = styled(animated.div)`
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 2;
`;

export const HamburgerContainer = styled.div`
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: flex-end;
`;

export const SidebarContentContainer = styled.div`
  padding-right: 16px;
  padding-left: 16px;
  height: calc(100% - 11vh);
`;
