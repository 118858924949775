import styled from "styled-components";
import { MOBILE_WIDTH } from "../../constants";
import { Link } from "gatsby";
import { Icon } from "@iconify/react";

export const OverheaderContainer = styled.div`
  height: 40px;
  background-color: #000;
  padding-left: 32px;
  padding-right: 32px;
  max-width: 1600px;
  display: flex;
  align-items: center;
  justify-content: end;
  @media ${MOBILE_WIDTH} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const SocialsGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  margin-left: auto;
`;

export const SocialIcon = styled(Icon)`
  color: #ffffff;
  font-size: 24px;
  margin-left: 16px;
  @media ${MOBILE_WIDTH} {
    font-size: 1.5rem;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 80px;
  border-right: none;
  border-left: none;
  border-top: none;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #000;

  // -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  // -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  // box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
`;

export const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  padding-left: 32px;
  padding-right: 32px;
  max-width: 1600px;

  @media ${MOBILE_WIDTH} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const NavLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // width: 200px;
  width: fit-content;
  justify-content: space-between;
  font-size: 1.5rem;
  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    height: fit-content;
  }
`;

export const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  font-family: "Oswald", sans-serif;
  width: fit-content;
  font-weight: 500;
  line-height: 35.57px;
  margin-left: 16px;

  :hover {
    opacity: 0.6;
  }

  @media ${MOBILE_WIDTH} {
    margin-left: 0px;
    margin-top: 24px;
  }
`;

export const LogoTitle = styled.h1`
  color: #fff;
  font-family: Damion;
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  line-height: 60px;
`;

export const HamburgerButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  height: 32px;
  width: 32px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
