import styled from "styled-components";
import { MOBILE_WIDTH } from "../../constants";

export const WorkHistoryContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const WorkPieceContainer = styled.div`
  width: 100%;
  height: ${(props) => (props.$width ? `calc(${props.$width} + 112px)` : "100%")};
  @media ${MOBILE_WIDTH} {
    height: ${(props) => (props.$width ? `calc(${props.$width} + 97px)` : "100%")};
  }
`;

export const AlbumImage = styled.img`
  width: ${(props) => props.$width || "100%"};
  height: ${(props) => props.$width || "auto"};
`;
